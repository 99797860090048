export enum ResponseIds {
  Q1A1 = 'Q1A1',
  Q1A2 = 'Q1A2',
  Q1A3 = 'Q1A3',
  Q1A4 = 'Q1A4',
  Q1A5 = 'Q1A5',
  Q2A1 = 'Q2A1',
  Q2A2 = 'Q2A2',
  Q2A3 = 'Q2A3',
  Q2A4 = 'Q2A4',
  Q2A5 = 'Q2A5',
  Q3A1 = 'Q3A1',
  Q3A2 = 'Q3A2',
  Q3A3 = 'Q3A3',
  Q3A4 = 'Q3A4',
  Q3A5 = 'Q3A5',
  Q4A1 = 'Q4A1',
  Q4A2 = 'Q4A2',
  Q4A3 = 'Q4A3',
  Q4A4 = 'Q4A4',
  Q4A5 = 'Q4A5',
  Q5A1 = 'Q5A1',
  Q5A2 = 'Q5A2',
  Q5A3 = 'Q5A3',
  Q5A4 = 'Q5A4',
  Q5A5 = 'Q5A5',
  Q6A1 = 'Q6A1',
  Q6A2 = 'Q6A2',
  Q6A3 = 'Q6A3',
  Q6A4 = 'Q6A4',
  Q6A5 = 'Q6A5',
  Q7A1 = 'Q7A1',
  Q7A2 = 'Q7A2',
  Q7A3 = 'Q7A3',
  Q7A4 = 'Q7A4',
  Q7A5 = 'Q7A5',
  Q8A1 = 'Q8A1',
  Q8A2 = 'Q8A2',
  Q8A3 = 'Q8A3',
  Q8A4 = 'Q8A4',
  Q8A5 = 'Q8A5',
  Q9A1 = 'Q9A1',
  Q9A2 = 'Q9A2',
  Q9A3 = 'Q9A3',
  Q9A4 = 'Q9A4',
  Q9A5 = 'Q9A5',
  Q10A1 = 'Q10A1',
  Q10A2 = 'Q10A2',
  Q10A3 = 'Q10A3',
  Q10A4 = 'Q10A4',
  Q10A5 = 'Q10A5',
  Q11A1 = 'Q11A1',
  Q11A2 = 'Q11A2',
  Q11A3 = 'Q11A3',
  Q11A4 = 'Q11A4',
  Q11A5 = 'Q11A5',
  Q12A1 = 'Q12A1',
  Q12A2 = 'Q12A2',
  Q12A3 = 'Q12A3',
  Q12A4 = 'Q12A4',
  Q12A5 = 'Q12A5',
  Q13A1 = 'Q13A1',
  Q13A2 = 'Q13A2',
  Q13A3 = 'Q13A3',
  Q13A4 = 'Q13A4',
  Q13A5 = 'Q13A5'
}
