import { createStore } from 'vuex'
import * as ActionsTypes from '@/store/actionTypes'
import * as MutationTypes from '@/store/mutationTypes'
import STEPS, { QuestionIds, StepId } from '@/data/steps'
import Step from '@/models/Step'
import { MajorPointOfInterest, MinorPointOfInterest, PointOfInterest } from '@/models/PointOfInterest'

// Key `stepId` is of @type StepId
type StepAnswerMap = { [stepId: string]: string }
// Key `poi` is of @type PointOfInterest
type PointOfInterestValues = { [poi: string]: number }
interface State {
  stepId: StepId
  currentStep: Step
  answers: StepAnswerMap
  pointOfInterestValues: PointOfInterestValues
}

const defaultPointOfInterestValueState = () => {
  const pointOfInterestState: PointOfInterestValues = {}
  // Fill in defaults for both `MajorPointOfInterest`s and
  // `MinorPointOfInterest`s
  const pois = [
    ...Object.keys(MajorPointOfInterest),
    ...Object.keys(MinorPointOfInterest)
  ]
  pois.forEach((poi) => {
    pointOfInterestState[poi] = 0
  })

  return pointOfInterestState
}

export default createStore({
  state: <State>{
    stepId: QuestionIds.DEMOGRAPHIC,
    currentStep: STEPS[QuestionIds.Q1],
    answers: <StepAnswerMap>{},
    pointOfInterestValues: defaultPointOfInterestValueState()
  },
  mutations: {
    [MutationTypes.SET_STEP]: (state, payload: { step: StepId }) => {
      state.stepId = payload.step
    },
    [MutationTypes.SET_CURRENT_STEP]: (state, payload: { stepData: Step }) => {
      state.currentStep = { ...payload.stepData }
    },
    [MutationTypes.SET_ANSWERS]: (state, payload: { answer: StepAnswerMap }) => {
      state.answers = { ...payload.answer }
    },
    [MutationTypes.SET_POI_VALUES]: (state, payload: { pointOfInterestValues: PointOfInterestValues }) => {
      state.pointOfInterestValues = { ...payload.pointOfInterestValues }
    }
  },
  actions: {
    [ActionsTypes.NEXT_STEP]: ({ commit }, payload: { step: StepId }) => {
      commit(MutationTypes.SET_STEP, payload)
      commit(MutationTypes.SET_CURRENT_STEP, { stepData: STEPS[payload.step] })
    },
    [ActionsTypes.ADD_RESPONSE_VALUE]: ({ commit, state }, payload: { questionId: QuestionIds, answer: string }) => {
      const updatedAnswers = {
        ...state.answers,
        ...{
          [payload.questionId]: payload.answer
        }
      }
      commit(MutationTypes.SET_ANSWERS, { answer: updatedAnswers })
    },
    [ActionsTypes.UPDATE_POI_VALUE]: ({ commit, state }, payload: { poi: PointOfInterest, amount: number }) => {
      const { poi, amount } = payload
      const updatedPoiValues = {
        ...state.pointOfInterestValues,
        ...{
          [poi]: state.pointOfInterestValues[poi] + amount
        }
      }
      commit(MutationTypes.SET_POI_VALUES, { pointOfInterestValues: updatedPoiValues })
    }
  },
  modules: {
  }
})
