import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FinalStep = _resolveComponent("FinalStep")!
  const _component_RevealStep = _resolveComponent("RevealStep")!
  const _component_Step = _resolveComponent("Step")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.finalStep)
      ? (_openBlock(), _createBlock(_component_FinalStep, {
          key: 0,
          step: this.$store.state.currentStep
        }, null, 8, ["step"]))
      : _createCommentVNode("", true),
    (_ctx.revealStep)
      ? (_openBlock(), _createBlock(_component_RevealStep, { key: 1 }))
      : _createCommentVNode("", true),
    (!_ctx.finalStep && !_ctx.revealStep)
      ? (_openBlock(), _createBlock(_component_Step, {
          key: 2,
          step: this.$store.state.currentStep
        }, null, 8, ["step"]))
      : _createCommentVNode("", true)
  ], 64))
}