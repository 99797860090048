import { StepId } from '@/data/steps'

export enum AnswerType {
  BUTTON = 'button',
  RADIO = 'radio'
}

/**
 * @field {influenceValue} "Weight" of this response towards the associated
 *  @see Question `majorInfluenceValue` or `minorInfluenceValue` that
 *  contains this Answer
 */
export default interface Answer {
  type: AnswerType
  label: string
  // Use `label` if `value` is missing
  value?: string
  influenceValue?: number
  next: StepId
}
