
import { defineComponent, PropType } from 'vue'
import Step from '@/models/Step'
import store from '@/store'
import { BodyData, EndpointResponse } from '@/models/GoogleSheets'
import { getDisplayableQuestionIds, getUploadableQuestionIds, RevealQuestionIds } from '@/data/steps'

export default defineComponent({
  name: 'FinalStep',
  props: {
    step: Object as PropType<Step>
  },
  methods: {
    async uploadResponses () {
      const answers = { ...store.state.answers }

      const userEmail = answers[RevealQuestionIds.Q1]
      const responses: EndpointResponse[] = []

      const includedQuestionsToUpload = new Set(getUploadableQuestionIds())
      for (const stepId in answers) {
        if (includedQuestionsToUpload.has(stepId)) {
          responses.push({
            stepId,
            response: answers[stepId]
          })
        }
      }

      const body: BodyData = {
        user: userEmail,
        responses
      }

      await fetch('https://script.google.com/macros/s/AKfycbxGvyaLSk-feq8_kq8z4tZpvjKLtoRYaH7RAp4PNZ2FrOfd9ynNx14X2a69TwhqTpew/exec', {
        method: 'POST',
        mode: 'no-cors',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
      })
    }
  },
  computed: {
    displayedAnswers () {
      const answers = { ...store.state.answers }
      const displayableQuestions = getDisplayableQuestionIds()

      // Exclude @see ResponseIds
      const excludingResponses = Object.keys(answers)
        .filter((qId) => displayableQuestions.includes(qId))
        .reduce((cur, qId) => Object.assign(cur, { [qId]: answers[qId] }), {})

      return excludingResponses
    }
  }
})
