
import { defineComponent } from 'vue'
import FinalStep from '@/components/FinalStep.vue'
import RevealStep from '@/components/RevealStep.vue'
import Step from '@/components/Step.vue'
import store from './store'
import { QuestionIds } from './data/steps'

export default defineComponent({
  name: 'App',
  components: {
    Step,
    FinalStep,
    RevealStep
  },
  computed: {
    finalStep: () => store.state.stepId === QuestionIds.FIN,
    revealStep: () => store.state.stepId === QuestionIds.DEMOGRAPHIC
  }
})
