
import { defineComponent, PropType } from 'vue'
import store from '@/store'

import * as ActionTypes from '@/store/actionTypes'
import Answer, { AnswerType } from '@/models/Answer'
import Question from '@/models/Question'
import Step, { StepType } from '@/models/Step'
import { FEAT_ANSWER_TYPE } from '@/settings'
import { StepId } from '@/data/steps'

export default defineComponent({
  name: 'Step',
  props: {
    step: {
      type: Object as PropType<Step>,
      required: true
    }
  },
  data: (): { selected: Answer | null } => ({
    selected: null
  }),
  methods: {
    /**
     * Special handling for @see AnswerType.RADIO
     * There are two situations for special handling:
     * 1. Main body display for radio answers
     *  1a. Single centered column with horizontal gutters
     *  1b. Left-aligned radio selections
     * 2. Show `Continue` button under all radio answers on
     *  @see StepType.Question steps
     */
    radioAnswerHandling (step: Step) {
      if (FEAT_ANSWER_TYPE === AnswerType.RADIO) {
        if (step.type !== StepType.RESPONSE) {
          return true
        }
      }
      return false
    },
    nextStep (next: StepId) {
      store.dispatch(ActionTypes.NEXT_STEP, {
        step: next
      })
    },
    addResponseValue (answer: Answer) {
      const questionId = this?.step?.id
      const answerValue = answer.value || answer.label
      store.dispatch(ActionTypes.ADD_RESPONSE_VALUE, {
        questionId,
        answer: answerValue
      })
    },
    /**
     * @param question Data for this question
     * @param amount `influenceValue` from `screenData.options`
     */
    updatePointOfInterestValue (question: Question, amount: number) {
      const {
        majorPointOfInterest,
        majorInfluenceValue,
        minorPointOfInterest,
        minorInfluenceValue
      } = question

      if (majorPointOfInterest) {
        const majorCalculatedAmount = (majorInfluenceValue || 0) * (amount || 0)
        store.dispatch(ActionTypes.UPDATE_POI_VALUE, {
          poi: majorPointOfInterest,
          amount: majorCalculatedAmount
        })
      }

      if (minorPointOfInterest) {
        const minorCalculatedAmount = (minorInfluenceValue || 0) * (amount || 0)
        store.dispatch(ActionTypes.UPDATE_POI_VALUE, {
          poi: minorPointOfInterest,
          amount: minorCalculatedAmount
        })
      }
    }
  }
})
