import Question from '@/models/Question'
import Response from '@/models/Response'
import Step, { StepType } from '@/models/Step'
import { AnswerType } from '@/models/Answer'
import { ResponseIds } from '@/data/response'
import { FEAT_ANSWER_TYPE } from '@/settings'

export enum QuestionIds {
  /**
   * Refer to @see RevealQuestionIds for individual steps in this question
   */
  DEMOGRAPHIC = 'D',
  // CORE
  Q1 = 'Q1',
  Q2 = 'Q2',
  Q3 = 'Q3',
  CORE_COMPLETE = 'CORE_COMPLETE',
  // EPICORE
  Q4 = 'Q4',
  EPICORE_COMPLETE = 'EPICORE_COMPLETE',
  // RESOURCES
  Q5 = 'Q5',
  Q6 = 'Q6',
  RESOURCES_COMPLETE = 'RESOURCES_COMPLETE',
  // SOCIAL
  Q7 = 'Q7',
  Q8 = 'Q8',
  SOCIAL_COMPLETE = 'SOCIAL_COMPLETE',
  // EXTERNAL SELF
  Q9 = 'Q9',
  Q10 = 'Q10',
  EXTERNAL_SELF_COMPLETE = 'EXTERNAL_SELF_COMPLETE',
  // INTERNAL SELF
  Q11 = 'Q11',
  Q12 = 'Q12',
  Q13 = 'Q13',
  INTERNAL_SELF_COMPLETE = 'INTERNAL_SELF_COMPLETE',
  FIN = 'FIN'
}

/**
 * Managed by @see RevealStep.vue
 */
export enum RevealQuestionIds {
  Q1 = 'demographic_email',
  Q2 = 'demographic_age_range',
  Q3 = 'demographic_felt_age',
  Q4 = 'demographic_gender',
  Q5 = 'demographic_next_step'
}

/**
 * @see QuestionIds keys to exclude from display. Some keys are used as
 * temporary values and we don't care about the selected answers.
 */
export const EXCLUDED_QUESTION_ID_ANSWERS = [
  QuestionIds.CORE_COMPLETE,
  QuestionIds.EPICORE_COMPLETE,
  QuestionIds.RESOURCES_COMPLETE,
  QuestionIds.SOCIAL_COMPLETE,
  QuestionIds.EXTERNAL_SELF_COMPLETE,
  QuestionIds.INTERNAL_SELF_COMPLETE,
  QuestionIds.FIN
]

/**
 * @returns @see QuestionIds that we want to show the corresponding
 * actions for
 */
export const getDisplayableQuestionIds = () => {
  const exludedQuestionIdsSet = new Set(EXCLUDED_QUESTION_ID_ANSWERS)

  return (
    Object.keys(QuestionIds).filter((qId) => !exludedQuestionIdsSet.has(qId as QuestionIds))
  )
}

/**
 * @see QuestionIds and @see RevealQuestionIds keys to include in the uploaded
 * answers. Currently, this is includes all @see RevealQuestionIds and only
 * QuestionIds that are strictly `Q#`
 * @note The demographic email step is exluded because it is separated from the
 * rest of the responses in the upload body
 */
export const INCLUDED_QUESTION_IDS_TO_UPLOAD = [
  RevealQuestionIds.Q2,
  RevealQuestionIds.Q3,
  RevealQuestionIds.Q4,
  RevealQuestionIds.Q5,
  QuestionIds.Q1,
  QuestionIds.Q2,
  QuestionIds.Q3,
  QuestionIds.Q4,
  QuestionIds.Q5,
  QuestionIds.Q6,
  QuestionIds.Q7,
  QuestionIds.Q8,
  QuestionIds.Q9,
  QuestionIds.Q10,
  QuestionIds.Q11,
  QuestionIds.Q12,
  QuestionIds.Q13
]

/**
 * @returns @see QuestionIds that we want to upload the responses for to the
 * backend. @see INCLUDED_QUESTION_IDS_TO_UPLOAD
 * @todo Fix the reveal step question keying to follow a more consisten pattern
 * with the rest of the questions
 */
export const getUploadableQuestionIds = () => {
  const inludedQuestionIdsSet = new Set(INCLUDED_QUESTION_IDS_TO_UPLOAD)
  const allQuestions = [
    ...Object.keys(QuestionIds),
    ...Object.values(RevealQuestionIds)
  ]

  return (
    allQuestions.filter((qId) => inludedQuestionIdsSet.has(qId as QuestionIds))
  )
}

export type StepId = QuestionIds | ResponseIds

// `id` is type `QuestionIds` or `ResponseIds`
const STEPS: { [id: string]: Step | Question | Response } = {
  [QuestionIds.Q1]: {
    id: QuestionIds.Q1,
    type: StepType.QUESTION,
    screenData: {
      title: 'Core',
      body: 'Rate your perceived healthful eating and drinking habits',
      options: [
        {
          type: FEAT_ANSWER_TYPE,
          label: 'Help Me, Please. I get my whole grains from Lucky Charms and produce from pizza.',
          next: ResponseIds.Q1A1
        },
        {
          type: FEAT_ANSWER_TYPE,
          label: "Wouldn't hurt to have a few less sodas, margaritas, or soda-margaritas I guess?",
          next: ResponseIds.Q1A2
        },
        {
          type: FEAT_ANSWER_TYPE,
          label: "Unsure. I still fit into my pants, but I'm not sure about eating green things.",
          next: ResponseIds.Q1A3
        },
        {
          type: FEAT_ANSWER_TYPE,
          label: 'I know my macros, eat for nutrient density and know my way around a grocery store!',
          next: ResponseIds.Q1A4
        },
        {
          type: FEAT_ANSWER_TYPE,
          label: "I'm so good I don't need your help, I drink at least 8 servings of water a day, I count calories, I exercise based on what I eat, I try every diet, wait… I'm living a nightmare",
          next: ResponseIds.Q1A5
        }
      ]
    }
  },
  [ResponseIds.Q1A1]: {
    id: ResponseIds.Q1A1,
    type: StepType.RESPONSE,
    screenData: {
      title: 'Awesome, only up from here!',
      body: 'There are many ways to make subtle changes in your food and beverage lifestyle that will have profound changes on your health. A guide might be a good fit to help you navigate what may work for you!',
      options: [
        {
          type: AnswerType.BUTTON,
          label: 'Continue',
          next: QuestionIds.Q2
        }
      ]
    }
  },
  [ResponseIds.Q1A2]: {
    id: ResponseIds.Q1A2,
    type: StepType.RESPONSE,
    screenData: {
      title: 'Awesome, only up from here!',
      body: 'There are many ways to make subtle changes in your food and beverage lifestyle that will have profound changes on your health. A guide might be a good fit to help you navigate what may work for you!',
      options: [
        {
          type: AnswerType.BUTTON,
          label: 'Continue',
          next: QuestionIds.Q2
        }
      ]
    }
  },
  [ResponseIds.Q1A3]: {
    id: ResponseIds.Q1A3,
    type: StepType.RESPONSE,
    screenData: {
      title: 'Awesome, only up from here!',
      body: 'There are many ways to make subtle changes in your food and beverage lifestyle that will have profound changes on your health. A guide might be a good fit to help you navigate what may work for you!',
      options: [
        {
          type: AnswerType.BUTTON,
          label: 'Continue',
          next: QuestionIds.Q2
        }
      ]
    }
  },
  [ResponseIds.Q1A4]: {
    id: ResponseIds.Q1A4,
    type: StepType.RESPONSE,
    screenData: {
      title: 'Well done!',
      body: "If you're interested in tasting more enticing drinks check out our Atlas Library for Pallet Challenge tips like Popular Pairings or Recipe Hacking.",
      options: [
        {
          type: AnswerType.BUTTON,
          label: 'Continue',
          next: QuestionIds.Q2
        }
      ]
    }
  },
  [ResponseIds.Q1A5]: {
    id: ResponseIds.Q1A5,
    type: StepType.RESPONSE,
    screenData: {
      title: 'Awesome, only up from here!',
      body: 'There are many ways to make subtle changes in your food and beverage lifestyle that will have profound changes on your health. A guide might be a good fit to help you navigate what may work for you!',
      options: [
        {
          type: AnswerType.BUTTON,
          label: 'Continue',
          next: QuestionIds.Q2
        }
      ]
    }
  },
  [QuestionIds.Q2]: {
    id: QuestionIds.Q2,
    type: StepType.QUESTION,
    screenData: {
      title: 'Core',
      body: 'Rate your perceived movement (physical activity)',
      options: [
        {
          type: FEAT_ANSWER_TYPE,
          label: 'Does lifting my arm between doughnut dunks count?',
          next: ResponseIds.Q2A1
        },
        {
          type: FEAT_ANSWER_TYPE,
          label: "I can't recall when I last worked out, or sweat from intentional exertion :)",
          next: ResponseIds.Q2A2
        },
        {
          type: FEAT_ANSWER_TYPE,
          label: "I don't huff up a flight of stairs but two flights, I wake up fresh most days but stiff all the others.",
          next: ResponseIds.Q2A3
        },
        {
          type: FEAT_ANSWER_TYPE,
          label: 'Frequently active, feel strong, and have good health stats.',
          next: ResponseIds.Q2A4
        },
        {
          type: FEAT_ANSWER_TYPE,
          label: "Just finished my third w/o of the day.. I'm a pro, not a \"bro\", I swear.",
          next: ResponseIds.Q2A5
        }
      ]
    }
  },
  [ResponseIds.Q2A1]: {
    id: ResponseIds.Q2A1,
    type: StepType.RESPONSE,
    screenData: {
      title: 'On that note, we can help you.',
      body: 'Just know that not all fitness activities are equal or a good match for your needs. Gym classes, yoga, tai chi, and team sports are a TINY sample of the activities you can try to get you physically active. A guide might be a good fit for finding what gets you moving so feel free to Contact Us!',
      options: [
        {
          type: AnswerType.BUTTON,
          label: 'Continue',
          next: QuestionIds.Q3
        }
      ]
    }
  },
  [ResponseIds.Q2A2]: {
    id: ResponseIds.Q2A2,
    type: StepType.RESPONSE,
    screenData: {
      title: 'On that note, we can help you.',
      body: 'Just know that not all fitness activities are equal or a good match for your needs. Gym classes, yoga, tai chi, and team sports are a TINY sample of the activities you can try to get you physically active. A guide might be a good fit for finding what gets you moving so feel free to Contact Us!',
      options: [
        {
          type: AnswerType.BUTTON,
          label: 'Continue',
          next: QuestionIds.Q3
        }
      ]
    }
  },
  [ResponseIds.Q2A3]: {
    id: ResponseIds.Q2A3,
    type: StepType.RESPONSE,
    screenData: {
      title: 'On that note, we can help you.',
      body: 'Just know that not all fitness activities are equal or a good match for your needs. Gym classes, yoga, tai chi, and team sports are a TINY sample of the activities you can try to get you physically active. A guide might be a good fit for finding what gets you moving so feel free to Contact Us!',
      options: [
        {
          type: AnswerType.BUTTON,
          label: 'Continue',
          next: QuestionIds.Q3
        }
      ]
    }
  },
  [ResponseIds.Q2A4]: {
    id: ResponseIds.Q2A4,
    type: StepType.RESPONSE,
    screenData: {
      title: 'Fine work I must say!',
      body: "If you're ever interested in increasing cognitive function, relaxing more, and how some supplements impact your biochemistry you can always request a private one-on-one consult to access more fitness hacking secrets. Drop a line with our contact form!",
      options: [
        {
          type: AnswerType.BUTTON,
          label: 'Continue',
          next: QuestionIds.Q3
        }
      ]
    }
  },
  [ResponseIds.Q2A5]: {
    id: ResponseIds.Q2A5,
    type: StepType.RESPONSE,
    screenData: {
      title: 'On that note, we can help you.',
      body: 'Just know that not all fitness activities are equal or a good match for your needs. Gym classes, yoga, tai chi, and team sports are a TINY sample of the activities you can try to get you physically active. A guide might be a good fit for finding what gets you moving so feel free to Contact Us!',
      options: [
        {
          type: AnswerType.BUTTON,
          label: 'Continue',
          next: QuestionIds.Q3
        }
      ]
    }
  },
  [QuestionIds.Q3]: {
    id: QuestionIds.Q3,
    type: StepType.QUESTION,
    screenData: {
      title: 'Core',
      body: 'Rate your perceived Mental Clarity',
      options: [
        {
          type: FEAT_ANSWER_TYPE,
          label: "I'm about as bright as a paint-less car without headlights in a never-ended fog.",
          next: ResponseIds.Q3A1
        },
        {
          type: FEAT_ANSWER_TYPE,
          label: 'Brain fog, focus, and emotions come and go as quick as a goat feints.',
          next: ResponseIds.Q3A2
        },
        {
          type: FEAT_ANSWER_TYPE,
          label: "Some days I'm good and others I don't know what's going on. What is this again?",
          next: ResponseIds.Q3A3
        },
        {
          type: FEAT_ANSWER_TYPE,
          label: 'My mind palace is pretty clean, under ongoing construction and I have some keys I misplaced but can find when needed.',
          next: ResponseIds.Q3A4
        },
        {
          type: FEAT_ANSWER_TYPE,
          label: 'My mind palace is immaculate and I have keys to every door, I walk the floor plan nightly.',
          next: ResponseIds.Q3A5
        }
      ]
    }
  },
  [ResponseIds.Q3A1]: {
    id: ResponseIds.Q3A1,
    type: StepType.RESPONSE,
    screenData: {
      title: 'Fantastic, only up from here!',
      body: 'There are many ways to make subtle changes in to your diet, fitness, environment, and emotional wellbeing that can have profound changes on your cognitive health. A guide might be a good fit to navigate many options waiting for you to try!',
      options: [
        {
          type: AnswerType.BUTTON,
          label: 'Continue',
          next: QuestionIds.CORE_COMPLETE
        }
      ]
    }
  },
  [ResponseIds.Q3A2]: {
    id: ResponseIds.Q3A2,
    type: StepType.RESPONSE,
    screenData: {
      title: 'Fantastic, only up from here!',
      body: 'There are many ways to make subtle changes in to your diet, fitness, environment, and emotional wellbeing that can have profound changes on your cognitive health. A guide might be a good fit to navigate many options waiting for you to try!',
      options: [
        {
          type: AnswerType.BUTTON,
          label: 'Continue',
          next: QuestionIds.CORE_COMPLETE
        }
      ]
    }
  },
  [ResponseIds.Q3A3]: {
    id: ResponseIds.Q3A3,
    type: StepType.RESPONSE,
    screenData: {
      title: 'Fantastic, only up from here!',
      body: 'There are many ways to make subtle changes in to your diet, fitness, environment, and emotional wellbeing that can have profound changes on your cognitive health. A guide might be a good fit to navigate many options waiting for you to try!',
      options: [
        {
          type: AnswerType.BUTTON,
          label: 'Continue',
          next: QuestionIds.CORE_COMPLETE
        }
      ]
    }
  },
  [ResponseIds.Q3A4]: {
    id: ResponseIds.Q3A4,
    type: StepType.RESPONSE,
    screenData: {
      title: 'Your mental game is on!',
      body: 'Any time you feel brain fog creeping or your mental focus start to drift you can tap into our Health Atlas on Cognitive Maintenance or setup a one-on-one consult with a Guide.',
      options: [
        {
          type: AnswerType.BUTTON,
          label: 'Continue',
          next: QuestionIds.CORE_COMPLETE
        }
      ]
    }
  },
  [ResponseIds.Q3A5]: {
    id: ResponseIds.Q3A5,
    type: StepType.RESPONSE,
    screenData: {
      title: 'Fantastic, only up from here!',
      body: 'There are many ways to make subtle changes in to your diet, fitness, environment, and emotional wellbeing that can have profound changes on your cognitive health. A guide might be a good fit to navigate many options waiting for you to try!',
      options: [
        {
          type: AnswerType.BUTTON,
          label: 'Continue',
          next: QuestionIds.CORE_COMPLETE
        }
      ]
    }
  },
  [QuestionIds.CORE_COMPLETE]: {
    id: QuestionIds.CORE_COMPLETE,
    type: StepType.RESPONSE,
    screenData: {
      title: 'CORE COMPLETE!',
      body: "You've just wrapped the section on the primary influence of human health - the first Point of Interest on the Compass - nice work!\n\n\nNext up is EpiCore.",
      options: [
        {
          type: AnswerType.BUTTON,
          label: 'Continue',
          next: QuestionIds.Q4
        }
      ]
    }
  },
  [QuestionIds.Q4]: {
    id: QuestionIds.Q4,
    type: StepType.QUESTION,
    screenData: {
      title: 'Epicore',
      body: 'Rate your environment, this includes the home, work, and any other space you spend extensive time. Consider quality of sleep, air, water, access to green space, and living space.',
      options: [
        {
          type: FEAT_ANSWER_TYPE,
          label: 'I need to improve at least pretty much all of what you just said.',
          next: ResponseIds.Q4A1
        },
        {
          type: FEAT_ANSWER_TYPE,
          label: 'These factors are like entering a casino: sleeping and roulette are synonyms to me, craps give me the same odds as clean air, and the only water is in well drinks.',
          next: ResponseIds.Q4A2
        },
        {
          type: FEAT_ANSWER_TYPE,
          label: "At least 50% of those elements I feel good about and 50% of the time, I have a plant in my office, somewhere, and what's \"air quality\" mean?",
          next: ResponseIds.Q4A3
        },
        {
          type: FEAT_ANSWER_TYPE,
          label: 'I feel good about most of those environs almost consistently.',
          next: ResponseIds.Q4A4
        },
        {
          type: FEAT_ANSWER_TYPE,
          label: 'I live in a park, have air and fluoride filters, sleep through the night, and my house is a sanctuary… It must never be disturbed.',
          next: ResponseIds.Q4A5
        }
      ]
    }
  },
  [ResponseIds.Q4A1]: {
    id: ResponseIds.Q4A1,
    type: StepType.RESPONSE,
    screenData: {
      title: 'Well holy mackerel!',
      body: 'Sleep is key to all things, water energizes you more than you know, air quality can be influenced, and outdoor time is a primal necessity.  We can try some solutions ASAP with a review of the simple (yet subtle) influences that can be the key to unlocking a quality environment. A guide might be a good fit to start snoozing, drinking, and breathing cleaner!',
      options: [
        {
          type: AnswerType.BUTTON,
          label: 'Continue',
          next: QuestionIds.EPICORE_COMPLETE
        }
      ]
    }
  },
  [ResponseIds.Q4A2]: {
    id: ResponseIds.Q4A2,
    type: StepType.RESPONSE,
    screenData: {
      title: 'Well holy mackerel!',
      body: 'Sleep is key to all things, water energizes you more than you know, air quality can be influenced, and outdoor time is a primal necessity.  We can try some solutions ASAP with a review of the simple (yet subtle) influences that can be the key to unlocking a quality environment. A guide might be a good fit to start snoozing, drinking, and breathing cleaner!',
      options: [
        {
          type: AnswerType.BUTTON,
          label: 'Continue',
          next: QuestionIds.EPICORE_COMPLETE
        }
      ]
    }
  },
  [ResponseIds.Q4A3]: {
    id: ResponseIds.Q4A3,
    type: StepType.RESPONSE,
    screenData: {
      title: 'Well holy mackerel!',
      body: 'Sleep is key to all things, water energizes you more than you know, air quality can be influenced, and outdoor time is a primal necessity.  We can try some solutions ASAP with a review of the simple (yet subtle) influences that can be the key to unlocking a quality environment. A guide might be a good fit to start snoozing, drinking, and breathing cleaner!',
      options: [
        {
          type: AnswerType.BUTTON,
          label: 'Continue',
          next: QuestionIds.EPICORE_COMPLETE
        }
      ]
    }
  },
  [ResponseIds.Q4A4]: {
    id: ResponseIds.Q4A4,
    type: StepType.RESPONSE,
    screenData: {
      title: 'Kudos!',
      body: 'Good sleep, fresh water, and clean air can be hard to come by, we commend you.  Starting to fade in and out of sleep, or wake up more groggy than before you touched the pillow, sinus congestion from no where are signs your environment might be changing without you knowing! Book a consult to see what actions you can make today to keep you and your environment healthy.',
      options: [
        {
          type: AnswerType.BUTTON,
          label: 'Continue',
          next: QuestionIds.EPICORE_COMPLETE
        }
      ]
    }
  },
  [ResponseIds.Q4A5]: {
    id: ResponseIds.Q4A5,
    type: StepType.RESPONSE,
    screenData: {
      title: 'Well holy mackerel!',
      body: 'Sleep is key to all things, water energizes you more than you know, air quality can be influenced, and outdoor time is a primal necessity.  We can try some solutions ASAP with a review of the simple (yet subtle) influences that can be the key to unlocking a quality environment. A guide might be a good fit to start snoozing, drinking, and breathing cleaner!',
      options: [
        {
          type: AnswerType.BUTTON,
          label: 'Continue',
          next: QuestionIds.EPICORE_COMPLETE
        }
      ]
    }
  },
  [QuestionIds.EPICORE_COMPLETE]: {
    id: QuestionIds.EPICORE_COMPLETE,
    type: StepType.RESPONSE,
    screenData: {
      title: 'EPICORE COMPLETE!',
      body: 'The environmental influence on the core can be pretty clear - if you choose a Guided Program you can expect a deeper dive on this POI.\n\n\nOnwards to Resources!',
      options: [
        {
          type: AnswerType.BUTTON,
          label: 'Continue',
          next: QuestionIds.Q5
        }
      ]
    }
  },
  [QuestionIds.Q5]: {
    id: QuestionIds.Q5,
    type: StepType.QUESTION,
    screenData: {
      title: 'Resources',
      body: 'Rate your current perceived job status and/or career path',
      options: [
        {
          type: FEAT_ANSWER_TYPE,
          label: "My compass is demagnetized and I don't know what to do.",
          next: ResponseIds.Q5A1
        },
        {
          type: FEAT_ANSWER_TYPE,
          label: "Wouldn't hurt to get the right gear and explore new skills and/or a new path… or even new planet.",
          next: ResponseIds.Q5A2
        },
        {
          type: FEAT_ANSWER_TYPE,
          label: 'I have a map, compass, and resources to follow the original itinerary but maybe my destination needs updated?',
          next: ResponseIds.Q5A3
        },
        {
          type: FEAT_ANSWER_TYPE,
          label: 'I have a well-funded trip with a good itinerary, skill-building stops, and flexible on destination',
          next: ResponseIds.Q5A4
        },
        {
          type: FEAT_ANSWER_TYPE,
          label: 'On my way to everything I dream of being, having, and doing; no autographs please, if I fail I don’t know what I’ll do.',
          next: ResponseIds.Q5A5
        }
      ]
    }
  },
  [ResponseIds.Q5A1]: {
    id: ResponseIds.Q5A1,
    type: StepType.RESPONSE,
    screenData: {
      title: 'Just so happens...we got you!',
      body: 'If you feel your interests or goals start to change (or that its time to define them) a check-in to evaluate your skills, personality, perspective, and priorities can aid in getting you a job to meet all current needs and build a career to meet all future goals. Set up some time with a Guide to explore what professional path is next on your journey.',
      options: [
        {
          type: AnswerType.BUTTON,
          label: 'Continue',
          next: QuestionIds.Q6
        }
      ]
    }
  },
  [ResponseIds.Q5A2]: {
    id: ResponseIds.Q5A2,
    type: StepType.RESPONSE,
    screenData: {
      title: 'Just so happens...we got you!',
      body: 'If you feel your interests or goals start to change (or that its time to define them) a check-in to evaluate your skills, personality, perspective, and priorities can aid in getting you a job to meet all current needs and build a career to meet all future goals. Set up some time with a Guide to explore what professional path is next on your journey.',
      options: [
        {
          type: AnswerType.BUTTON,
          label: 'Continue',
          next: QuestionIds.Q6
        }
      ]
    }
  },
  [ResponseIds.Q5A3]: {
    id: ResponseIds.Q5A3,
    type: StepType.RESPONSE,
    screenData: {
      title: 'Just so happens...we got you!',
      body: 'If you feel your interests or goals start to change (or that its time to define them) a check-in to evaluate your skills, personality, perspective, and priorities can aid in getting you a job to meet all current needs and build a career to meet all future goals. Set up some time with a Guide to explore what professional path is next on your journey.',
      options: [
        {
          type: AnswerType.BUTTON,
          label: 'Continue',
          next: QuestionIds.Q6
        }
      ]
    }
  },
  [ResponseIds.Q5A4]: {
    id: ResponseIds.Q5A4,
    type: StepType.RESPONSE,
    screenData: {
      title: 'Way to figure it out!',
      body: "If you feel your interests or goals start to change it's always good to check-in and evaluate your updated skills, personality, perspective, and priorities which can help guide you on your professional path. If you need a soundboard or a fresh look at navigating your place in a career drop us a line for a one-on-one with Guide to assist your evolving needs.",
      options: [
        {
          type: AnswerType.BUTTON,
          label: 'Continue',
          next: QuestionIds.Q6
        }
      ]
    }
  },
  [ResponseIds.Q5A5]: {
    id: ResponseIds.Q5A5,
    type: StepType.RESPONSE,
    screenData: {
      title: 'Just so happens...we got you!',
      body: 'If you feel your interests or goals start to change (or that its time to define them) a check-in to evaluate your skills, personality, perspective, and priorities can aid in getting you a job to meet all current needs and build a career to meet all future goals. Set up some time with a Guide to explore what professional path is next on your journey.',
      options: [
        {
          type: AnswerType.BUTTON,
          label: 'Continue',
          next: QuestionIds.Q6
        }
      ]
    }
  },
  [QuestionIds.Q6]: {
    id: QuestionIds.Q6,
    type: StepType.QUESTION,
    screenData: {
      title: 'Resources',
      body: 'Rate your current perceived education',
      options: [
        {
          type: FEAT_ANSWER_TYPE,
          label: "I'm in need of a refresher or more education to build off basic training/knowledge or get what I've been missing",
          next: ResponseIds.Q6A1
        },
        {
          type: FEAT_ANSWER_TYPE,
          label: 'I have the skills and know-how to meet current needs but need different ones to explore where I want to travel next.',
          next: ResponseIds.Q6A2
        },
        {
          type: FEAT_ANSWER_TYPE,
          label: "I have amateur training to meet current skill needs, there's always YouTube if I need more.",
          next: ResponseIds.Q6A3
        },
        {
          type: FEAT_ANSWER_TYPE,
          label: "Doing pretty well as-is! I like to learn new things and be good at things so I'm continuously seeking more information.",
          next: ResponseIds.Q6A4
        },
        {
          type: FEAT_ANSWER_TYPE,
          label: "You have something I don't already know? I seek to know everything or already do. If I don't know it I will find it on my own but never tell you I didn't know it until I did.",
          next: ResponseIds.Q6A5
        }
      ]
    }
  },
  [ResponseIds.Q6A1]: {
    id: ResponseIds.Q6A1,
    type: StepType.RESPONSE,
    screenData: {
      title: 'Education goes beyond formal institutes or certification',
      body: 'Continuing our learning in various ways allows us to "flex our mind" and develop our sense of self. It can be as simple as learning a new skill on YouTube, exposing ourselves to new genres of music, or learning to play a new game! Education, in all its forms, can be empowering and influence you in ways you\'d never predict. A guide sounds like a good fit to help you explore the diverse opportunities out there and get your mental workout on!',
      options: [
        {
          type: AnswerType.BUTTON,
          label: 'Continue',
          next: QuestionIds.RESOURCES_COMPLETE
        }
      ]
    }
  },
  [ResponseIds.Q6A2]: {
    id: ResponseIds.Q6A2,
    type: StepType.RESPONSE,
    screenData: {
      title: 'Education goes beyond formal institutes or certification',
      body: 'Continuing our learning in various ways allows us to "flex our mind" and develop our sense of self. It can be as simple as learning a new skill on YouTube, exposing ourselves to new genres of music, or learning to play a new game! Education, in all its forms, can be empowering and influence you in ways you\'d never predict. A guide sounds like a good fit to help you explore the diverse opportunities out there and get your mental workout on!',
      options: [
        {
          type: AnswerType.BUTTON,
          label: 'Continue',
          next: QuestionIds.RESOURCES_COMPLETE
        }
      ]
    }
  },
  [ResponseIds.Q6A3]: {
    id: ResponseIds.Q6A3,
    type: StepType.RESPONSE,
    screenData: {
      title: 'Education goes beyond formal institutes or certification',
      body: 'Continuing our learning in various ways allows us to "flex our mind" and develop our sense of self. It can be as simple as learning a new skill on YouTube, exposing ourselves to new genres of music, or learning to play a new game! Education, in all its forms, can be empowering and influence you in ways you\'d never predict. A guide sounds like a good fit to help you explore the diverse opportunities out there and get your mental workout on!',
      options: [
        {
          type: AnswerType.BUTTON,
          label: 'Continue',
          next: QuestionIds.RESOURCES_COMPLETE
        }
      ]
    }
  },
  [ResponseIds.Q6A4]: {
    id: ResponseIds.Q6A4,
    type: StepType.RESPONSE,
    screenData: {
      title: 'Kudos!',
      body: "Learning is growing and if we're not growing we're dying (some people say). Keep up the good work and if you find yourself curious to explore other means of self-education or the value of diversified learning (music, language, art, logic engineering, tangible action, etc.*). Reach out for a one-on-one with a Guide who can propose alternative forms of learning or help you stay accountable while pursuing your education goals.",
      options: [
        {
          type: AnswerType.BUTTON,
          label: 'Continue',
          next: QuestionIds.RESOURCES_COMPLETE
        }
      ]
    }
  },
  [ResponseIds.Q6A5]: {
    id: ResponseIds.Q6A5,
    type: StepType.RESPONSE,
    screenData: {
      title: 'Education goes beyond formal institutes or certification',
      body: 'Continuing our learning in various ways allows us to "flex our mind" and develop our sense of self. It can be as simple as learning a new skill on YouTube, exposing ourselves to new genres of music, or learning to play a new game! Education, in all its forms, can be empowering and influence you in ways you\'d never predict. A guide sounds like a good fit to help you explore the diverse opportunities out there and get your mental workout on!',
      options: [
        {
          type: AnswerType.BUTTON,
          label: 'Continue',
          next: QuestionIds.RESOURCES_COMPLETE
        }
      ]
    }
  },
  [QuestionIds.RESOURCES_COMPLETE]: {
    id: QuestionIds.RESOURCES_COMPLETE,
    type: StepType.RESPONSE,
    screenData: {
      title: 'RESOURCES COMPLETE!',
      body: 'The paths available can seem out of our control at times yet, we can invest our time and energy to create more paths to choose how to move forward on.\n\n\nOnwards to Social dynamics!',
      options: [
        {
          type: AnswerType.BUTTON,
          label: 'Continue',
          next: QuestionIds.Q7
        }
      ]
    }
  },
  [QuestionIds.Q7]: {
    id: QuestionIds.Q7,
    type: StepType.QUESTION,
    screenData: {
      title: 'Social',
      body: 'Rate your current perceived relationship with "self" (independence, self-care, constructive growth, etc.*)',
      options: [
        {
          type: FEAT_ANSWER_TYPE,
          label: "What are these things you're saying? I often blame myself or others when expectations don't meet reality.",
          next: ResponseIds.Q7A1
        },
        {
          type: FEAT_ANSWER_TYPE,
          label: "I generally do whatever I'm told and aim to please others *or* often ignore the needs of those close to me.",
          next: ResponseIds.Q7A2
        },
        {
          type: FEAT_ANSWER_TYPE,
          label: 'Is that like applying your own first aid? Explain "self care"?',
          next: ResponseIds.Q7A3
        },
        {
          type: FEAT_ANSWER_TYPE,
          label: 'I take care of my health, own my own choices, think constructively and I am able to help others without sacrificing my own care.',
          next: ResponseIds.Q7A4
        },
        {
          type: FEAT_ANSWER_TYPE,
          label: "I spend lots of time on self-care, helping myself over others often- In fact I'm great at telling others what to do for me",
          next: ResponseIds.Q7A5
        }
      ]
    }
  },
  [ResponseIds.Q7A1]: {
    id: ResponseIds.Q7A1,
    type: StepType.RESPONSE,
    screenData: {
      title: 'Well, we can help with that!',
      body: "Seems people help others best when they're taking care of themselves too. We believe this to be a fundamental for longevity and positive change.\n\nIt takes as little as 15 minutes to give yourself some self care rejuvenation. From mocktails and mask therapy to trying a new interest - you can get a lot out of adding \"me\" back into time.\n\nA guide sounds like a good fit for tips on getting more (or any) self care, ideas for new experiences, and learning how self-care impacts the other areas of your health.",
      options: [
        {
          type: AnswerType.BUTTON,
          label: 'Continue',
          next: QuestionIds.Q8
        }
      ]
    }
  },
  [ResponseIds.Q7A2]: {
    id: ResponseIds.Q7A2,
    type: StepType.RESPONSE,
    screenData: {
      title: 'Well, we can help with that!',
      body: "Seems people help others best when they're taking care of themselves too. We believe this to be a fundamental for longevity and positive change.\n\nIt takes as little as 15 minutes to give yourself some self care rejuvenation. From mocktails and mask therapy to trying a new interest - you can get a lot out of adding \"me\" back into time.\n\nA guide sounds like a good fit for tips on getting more (or any) self care, ideas for new experiences, and learning how self-care impacts the other areas of your health.",
      options: [
        {
          type: AnswerType.BUTTON,
          label: 'Continue',
          next: QuestionIds.Q8
        }
      ]
    }
  },
  [ResponseIds.Q7A3]: {
    id: ResponseIds.Q7A3,
    type: StepType.RESPONSE,
    screenData: {
      title: 'Well, we can help with that!',
      body: "Seems people help others best when they're taking care of themselves too. We believe this to be a fundamental for longevity and positive change.\n\nIt takes as little as 15 minutes to give yourself some self care rejuvenation. From mocktails and mask therapy to trying a new interest - you can get a lot out of adding \"me\" back into time.\n\nA guide sounds like a good fit for tips on getting more (or any) self care, ideas for new experiences, and learning how self-care impacts the other areas of your health.",
      options: [
        {
          type: AnswerType.BUTTON,
          label: 'Continue',
          next: QuestionIds.Q8
        }
      ]
    }
  },
  [ResponseIds.Q7A4]: {
    id: ResponseIds.Q7A4,
    type: StepType.RESPONSE,
    screenData: {
      title: 'Now this is what we like to hear!',
      body: "We enjoy learning you're saving the best for first (you). People tend to be more helpful, productive, at ease, and in a positive disposition when getting the care they need.\n\nDo you have mocktail and mask therapy or try new interests solo? If not, book a Reservation Only session for a refreshing approach to self care through curated menus, products, and events near you.",
      options: [
        {
          type: AnswerType.BUTTON,
          label: 'Continue',
          next: QuestionIds.Q8
        }
      ]
    }
  },
  [ResponseIds.Q7A5]: {
    id: ResponseIds.Q7A5,
    type: StepType.RESPONSE,
    screenData: {
      title: 'Well, we can help with that!',
      body: "Seems people help others best when they're taking care of themselves too. We believe this to be a fundamental for longevity and positive change.\n\nIt takes as little as 15 minutes to give yourself some self care rejuvenation. From mocktails and mask therapy to trying a new interest - you can get a lot out of adding \"me\" back into time.\n\nA guide sounds like a good fit for tips on getting more (or any) self care, ideas for new experiences, and learning how self-care impacts the other areas of your health.",
      options: [
        {
          type: AnswerType.BUTTON,
          label: 'Continue',
          next: QuestionIds.Q8
        }
      ]
    }
  },
  [QuestionIds.Q8]: {
    id: QuestionIds.Q8,
    type: StepType.QUESTION,
    screenData: {
      title: 'Social',
      body: 'Rate your current relationship within and outside the home. This includes family, romantic partner(s), housemates, friendships, colleagues (professional/work-related), and community',
      options: [
        {
          type: FEAT_ANSWER_TYPE,
          label: 'I have no hobbies and I hate people.',
          next: ResponseIds.Q8A1
        },
        {
          type: FEAT_ANSWER_TYPE,
          label: "It's just so complicated. I mean avoidance and passive aggression is normal right?",
          next: ResponseIds.Q8A2
        },
        {
          type: FEAT_ANSWER_TYPE,
          label: 'Could have more meaningful conversations. I have good rapport with some people, some "meh", occasionally one is funny or has a novel idea.',
          next: ResponseIds.Q8A3
        },
        {
          type: FEAT_ANSWER_TYPE,
          label: 'I am supported and I support. Often feeling enabled to be a better person in the presence of the people I keep close.',
          next: ResponseIds.Q8A4
        },
        {
          type: FEAT_ANSWER_TYPE,
          label: "I have no interaction with anyone or thing at home, unless my pet fish counts - I'm not sure how I can change this or if I want to.",
          next: ResponseIds.Q8A5
        }
      ]
    }
  },
  [ResponseIds.Q8A1]: {
    id: ResponseIds.Q8A1,
    type: StepType.RESPONSE,
    screenData: {
      title: 'Well...we can help you out on this one!',
      body: "The impact on our subconscious decisions based on our sense of belonging and fulfillment can be tied to our social dynamics.\n\nIf your longevity is getting directly hit by your social circles, let's talk about it.\n\nA guide sounds like a good fit to help you navigate to friendlier waters.",
      options: [
        {
          type: AnswerType.BUTTON,
          label: 'Continue',
          next: QuestionIds.SOCIAL_COMPLETE
        }
      ]
    }
  },
  [ResponseIds.Q8A2]: {
    id: ResponseIds.Q8A2,
    type: StepType.RESPONSE,
    screenData: {
      title: 'Well...we can help you out on this one!',
      body: "The impact on our subconscious decisions based on our sense of belonging and fulfillment can be tied to our social dynamics.\n\nIf your longevity is getting directly hit by your social circles, let's talk about it.\n\nA guide sounds like a good fit to help you navigate to friendlier waters.",
      options: [
        {
          type: AnswerType.BUTTON,
          label: 'Continue',
          next: QuestionIds.SOCIAL_COMPLETE
        }
      ]
    }
  },
  [ResponseIds.Q8A3]: {
    id: ResponseIds.Q8A3,
    type: StepType.RESPONSE,
    screenData: {
      title: 'Well...we can help you out on this one!',
      body: "The impact on our subconscious decisions based on our sense of belonging and fulfillment can be tied to our social dynamics.\n\nIf your longevity is getting directly hit by your social circles, let's talk about it.\n\nA guide sounds like a good fit to help you navigate to friendlier waters.",
      options: [
        {
          type: AnswerType.BUTTON,
          label: 'Continue',
          next: QuestionIds.SOCIAL_COMPLETE
        }
      ]
    }
  },
  [ResponseIds.Q8A4]: {
    id: ResponseIds.Q8A4,
    type: StepType.RESPONSE,
    screenData: {
      title: 'All those songs about circles of friends really paid off!',
      body: "Way to go at keeping the good ones around and, in turn being a kind, caring, and compassionate human (we know that's not easy). Check in on this often. Our relationships often impact us subtly and their true impact can go unnoticed for some time especially if we're trying to avoid confrontation or harsh truths.\n\nFor now, keep on keeping on and let us know if you have any tips for maintaining solid relationships!",
      options: [
        {
          type: AnswerType.BUTTON,
          label: 'Continue',
          next: QuestionIds.SOCIAL_COMPLETE
        }
      ]
    }
  },
  [ResponseIds.Q8A5]: {
    id: ResponseIds.Q8A5,
    type: StepType.RESPONSE,
    screenData: {
      title: 'Well...we can help you out on this one!',
      body: "The impact on our subconscious decisions based on our sense of belonging and fulfillment can be tied to our social dynamics.\n\nIf your longevity is getting directly hit by your social circles, let's talk about it.\n\nA guide sounds like a good fit to help you navigate to friendlier waters.",
      options: [
        {
          type: AnswerType.BUTTON,
          label: 'Continue',
          next: QuestionIds.SOCIAL_COMPLETE
        }
      ]
    }
  },
  [QuestionIds.SOCIAL_COMPLETE]: {
    id: QuestionIds.SOCIAL_COMPLETE,
    type: StepType.RESPONSE,
    screenData: {
      title: 'SOCIAL COMPLETE!',
      body: "You've made it halfway!\n\n\nHopefully you've just taken a good look at how people influence your life and are ready to move on to how YOU influence, well, you!\n\n\nNext up, External Self.",
      options: [
        {
          type: AnswerType.BUTTON,
          label: 'Continue',
          next: QuestionIds.Q9
        }
      ]
    }
  },
  [QuestionIds.Q9]: {
    id: QuestionIds.Q9,
    type: StepType.QUESTION,
    screenData: {
      title: 'External Self',
      body: 'Rate your current level of Passive Creativity (ideation and exploration of ideas) and Active Creativity (application of ideas)',
      options: [
        {
          type: FEAT_ANSWER_TYPE,
          label: 'I got as far as stick-figures in art class then booked it.',
          next: ResponseIds.Q9A1
        },
        {
          type: FEAT_ANSWER_TYPE,
          label: 'I had to fold paper into a fortune teller once but I put flavors of ice cream as the future predictions... hehe.',
          next: ResponseIds.Q9A2
        },
        {
          type: FEAT_ANSWER_TYPE,
          label: 'Does folding my delivery boxes into cat houses count?',
          next: ResponseIds.Q9A3
        },
        {
          type: FEAT_ANSWER_TYPE,
          label: 'I try new things I come up with often. The beer bottle terrariums outdid the sandwich holder birdhouses.',
          next: ResponseIds.Q9A4
        },
        {
          type: FEAT_ANSWER_TYPE,
          label: 'I have three revenue streams through Etsy, Amazon, and YouTube but have started to lose creativity to obligation',
          next: ResponseIds.Q9A5
        }
      ]
    }
  },
  [ResponseIds.Q9A1]: {
    id: ResponseIds.Q9A1,
    type: StepType.RESPONSE,
    screenData: {
      title: 'Creativity goes beyond art folks!',
      body: "You're ready to get your creative game on and flex those \"mind muscles\". Whether you have an idea you've been holding onto (a library of them is also acceptable) or unsure where to start, a guide seems a good fit to explore the satisfaction (and benefits) that come from crafting an idea and bringing its creation to life!",
      options: [
        {
          type: AnswerType.BUTTON,
          label: 'Continue',
          next: QuestionIds.Q10
        }
      ]
    }
  },
  [ResponseIds.Q9A2]: {
    id: ResponseIds.Q9A2,
    type: StepType.RESPONSE,
    screenData: {
      title: 'Creativity goes beyond art folks!',
      body: "You're ready to get your creative game on and flex those \"mind muscles\". Whether you have an idea you've been holding onto (a library of them is also acceptable) or unsure where to start, a guide seems a good fit to explore the satisfaction (and benefits) that come from crafting an idea and bringing its creation to life!",
      options: [
        {
          type: AnswerType.BUTTON,
          label: 'Continue',
          next: QuestionIds.Q10
        }
      ]
    }
  },
  [ResponseIds.Q9A3]: {
    id: ResponseIds.Q9A3,
    type: StepType.RESPONSE,
    screenData: {
      title: 'Creativity goes beyond art folks!',
      body: "You're ready to get your creative game on and flex those \"mind muscles\". Whether you have an idea you've been holding onto (a library of them is also acceptable) or unsure where to start, a guide seems a good fit to explore the satisfaction (and benefits) that come from crafting an idea and bringing its creation to life!",
      options: [
        {
          type: AnswerType.BUTTON,
          label: 'Continue',
          next: QuestionIds.Q10
        }
      ]
    }
  },
  [ResponseIds.Q9A4]: {
    id: ResponseIds.Q9A4,
    type: StepType.RESPONSE,
    screenData: {
      title: 'Yes, yes very good. This is what we like to hear!',
      body: "Creative ideation and application are intertwined but unique at the same time.\n\nExercising both is the key to developing many other aspects of your health and so we commend you for your execution (generally the toughest of the two).\n\nIf you're looking to expand your creative capacity or try a new form of application book a Health Challenge to see what we can challenge you to.",
      options: [
        {
          type: AnswerType.BUTTON,
          label: 'Continue',
          next: QuestionIds.Q10
        }
      ]
    }
  },
  [ResponseIds.Q9A5]: {
    id: ResponseIds.Q9A5,
    type: StepType.RESPONSE,
    screenData: {
      title: 'Creativity goes beyond art folks!',
      body: "You're ready to get your creative game on and flex those \"mind muscles\". Whether you have an idea you've been holding onto (a library of them is also acceptable) or unsure where to start, a guide seems a good fit to explore the satisfaction (and benefits) that come from crafting an idea and bringing its creation to life!",
      options: [
        {
          type: AnswerType.BUTTON,
          label: 'Continue',
          next: QuestionIds.Q10
        }
      ]
    }
  },
  [QuestionIds.Q10]: {
    id: QuestionIds.Q10,
    type: StepType.QUESTION,
    screenData: {
      title: 'External Self',
      body: "Rate your current level of Confidence (TIP: answer how you feel in interactions with people you know/don't know on topics you know/don't know)",
      options: [
        {
          type: FEAT_ANSWER_TYPE,
          label: "I'm not sure about much and communicate even less even in the presence of pizza.",
          next: ResponseIds.Q10A1
        },
        {
          type: FEAT_ANSWER_TYPE,
          label: "I'm pretty shy and avoid direct eye contact but can talk at times IF cookies are involved, it would be nice to not require cookies...",
          next: ResponseIds.Q10A2
        },
        {
          type: FEAT_ANSWER_TYPE,
          label: 'I keep to the cheese board table as I can hold a conversation but would like to feel better around unfamiliar people or topics.',
          next: ResponseIds.Q10A3
        },
        {
          type: FEAT_ANSWER_TYPE,
          label: 'Give me the mic! I feel comfortable discussing topics I know little or everything about with people I know well or not at all.',
          next: ResponseIds.Q10A4
        },
        {
          type: FEAT_ANSWER_TYPE,
          label: "I can own any conversation, doesn't matter if I know what I'm talking about or not, I'll... oh, or maybe I'm rating my arrogance?",
          next: ResponseIds.Q10A5
        }
      ]
    }
  },
  [ResponseIds.Q10A1]: {
    id: ResponseIds.Q10A1,
    type: StepType.RESPONSE,
    screenData: {
      title: 'We got you.',
      body: "There's a root cause to everything and when left unresolved or unnoticed for too long it often it gets mixed up with other issues or pain points. This \"overgrowth\" can get more and more difficult to trek through, where at some point you just stop.\n\nTo help you navigate the jungle so you can enjoy the scenery rather than get swallowed by it a guide sounds like a good fit.\n\nA guide can help you hack through the overgrowth (the sources burdening you) and reveal the path for your to continue on your way, wherever you want to go.",
      options: [
        {
          type: AnswerType.BUTTON,
          label: 'Continue',
          next: QuestionIds.EXTERNAL_SELF_COMPLETE
        }
      ]
    }
  },
  [ResponseIds.Q10A2]: {
    id: ResponseIds.Q10A2,
    type: StepType.RESPONSE,
    screenData: {
      title: 'We got you.',
      body: "There's a root cause to everything and when left unresolved or unnoticed for too long it often it gets mixed up with other issues or pain points. This \"overgrowth\" can get more and more difficult to trek through, where at some point you just stop.\n\nTo help you navigate the jungle so you can enjoy the scenery rather than get swallowed by it a guide sounds like a good fit.\n\nA guide can help you hack through the overgrowth (the sources burdening you) and reveal the path for your to continue on your way, wherever you want to go.",
      options: [
        {
          type: AnswerType.BUTTON,
          label: 'Continue',
          next: QuestionIds.EXTERNAL_SELF_COMPLETE
        }
      ]
    }
  },
  [ResponseIds.Q10A3]: {
    id: ResponseIds.Q10A3,
    type: StepType.RESPONSE,
    screenData: {
      title: 'We got you.',
      body: "There's a root cause to everything and when left unresolved or unnoticed for too long it often it gets mixed up with other issues or pain points. This \"overgrowth\" can get more and more difficult to trek through, where at some point you just stop.\n\nTo help you navigate the jungle so you can enjoy the scenery rather than get swallowed by it a guide sounds like a good fit.\n\nA guide can help you hack through the overgrowth (the sources burdening you) and reveal the path for your to continue on your way, wherever you want to go.",
      options: [
        {
          type: AnswerType.BUTTON,
          label: 'Continue',
          next: QuestionIds.EXTERNAL_SELF_COMPLETE
        }
      ]
    }
  },
  [ResponseIds.Q10A4]: {
    id: ResponseIds.Q10A4,
    type: StepType.RESPONSE,
    screenData: {
      title: 'YESSSSS',
      body: "We hope there's some humility to harness you against that shear face of confidence. Way to get where so many strive to be.\n\nIf at any time you start to replace confidence with ego or find yourself needing a little boost, go ahead and book a one-on-one with a Guide to recalibrate your compass. Safe travels!",
      options: [
        {
          type: AnswerType.BUTTON,
          label: 'Continue',
          next: QuestionIds.EXTERNAL_SELF_COMPLETE
        }
      ]
    }
  },
  [ResponseIds.Q10A5]: {
    id: ResponseIds.Q10A5,
    type: StepType.RESPONSE,
    screenData: {
      title: 'We got you.',
      body: "There's a root cause to everything and when left unresolved or unnoticed for too long it often it gets mixed up with other issues or pain points. This \"overgrowth\" can get more and more difficult to trek through, where at some point you just stop.\n\nTo help you navigate the jungle so you can enjoy the scenery rather than get swallowed by it a guide sounds like a good fit.\n\nA guide can help you hack through the overgrowth (the sources burdening you) and reveal the path for your to continue on your way, wherever you want to go.",
      options: [
        {
          type: AnswerType.BUTTON,
          label: 'Continue',
          next: QuestionIds.EXTERNAL_SELF_COMPLETE
        }
      ]
    }
  },
  [QuestionIds.EXTERNAL_SELF_COMPLETE]: {
    id: QuestionIds.EXTERNAL_SELF_COMPLETE,
    type: StepType.RESPONSE,
    screenData: {
      title: 'EXTERNAL SELF COMPLETE!',
      body: 'Almost to the last POI and the other half of self... Internal Self',
      options: [
        {
          type: AnswerType.BUTTON,
          label: 'Continue',
          next: QuestionIds.Q11
        }
      ]
    }
  },
  [QuestionIds.Q11]: {
    id: QuestionIds.Q11,
    type: StepType.QUESTION,
    screenData: {
      title: 'Internal Self',
      body: 'Rate your frequency of experiencing joy',
      options: [
        {
          type: FEAT_ANSWER_TYPE,
          label: 'What is this thing you speak of? I think I had this when I got a hole-in-one at putt-putt a few years ago or does smelling fresh laundry count?',
          next: ResponseIds.Q11A1
        },
        {
          type: FEAT_ANSWER_TYPE,
          label: 'I feel joy like riding a rollercoaster, feeling it in surges but losing it just as fast.',
          next: ResponseIds.Q11A2
        },
        {
          type: FEAT_ANSWER_TYPE,
          label: 'I find little moments and appreciate them but when or why they happen eludes me.',
          next: ResponseIds.Q11A3
        },
        {
          type: FEAT_ANSWER_TYPE,
          label: 'Joy is at minimum a daily occurrence and I appreciate every moment it happens.',
          next: ResponseIds.Q11A4
        },
        {
          type: FEAT_ANSWER_TYPE,
          label: 'I seek joy in all things and do not spend time on people or things that might not bring it.',
          next: ResponseIds.Q11A5
        }
      ]
    }
  },
  [ResponseIds.Q11A1]: {
    id: ResponseIds.Q11A1,
    type: StepType.RESPONSE,
    screenData: {
      title: 'We got you!',
      body: 'Many people mix up joy (fleeting and mindful in the moment) and fulfillment (sometimes painful yet enduring).  Joy can be experienced and unnoticed or lacking and in need of development.\n\nA guide sounds like a good fit to help you understand the importance of joy, appreciating it, and discovering your own source(s) to tap into!',
      options: [
        {
          type: AnswerType.BUTTON,
          label: 'Continue',
          next: QuestionIds.Q12
        }
      ]
    }
  },
  [ResponseIds.Q11A2]: {
    id: ResponseIds.Q11A2,
    type: StepType.RESPONSE,
    screenData: {
      title: 'We got you!',
      body: 'Many people mix up joy (fleeting and mindful in the moment) and fulfillment (sometimes painful yet enduring).  Joy can be experienced and unnoticed or lacking and in need of development.\n\nA guide sounds like a good fit to help you understand the importance of joy, appreciating it, and discovering your own source(s) to tap into!',
      options: [
        {
          type: AnswerType.BUTTON,
          label: 'Continue',
          next: QuestionIds.Q12
        }
      ]
    }
  },
  [ResponseIds.Q11A3]: {
    id: ResponseIds.Q11A3,
    type: StepType.RESPONSE,
    screenData: {
      title: 'We got you!',
      body: 'Many people mix up joy (fleeting and mindful in the moment) and fulfillment (sometimes painful yet enduring).  Joy can be experienced and unnoticed or lacking and in need of development.\n\nA guide sounds like a good fit to help you understand the importance of joy, appreciating it, and discovering your own source(s) to tap into!',
      options: [
        {
          type: AnswerType.BUTTON,
          label: 'Continue',
          next: QuestionIds.Q12
        }
      ]
    }
  },
  [ResponseIds.Q11A4]: {
    id: ResponseIds.Q11A4,
    type: StepType.RESPONSE,
    screenData: {
      title: 'GREAT!',
      body: "We're so happy you know the difference between joy (fleeting) and fulfillment (sometimes painful, yet enduring). Experiencing and appreciating joy are two events that craft this area of health. If you're looking to achieve more joy book a Reservation Only session to explore new ways to achieve or enhance joy.",
      options: [
        {
          type: AnswerType.BUTTON,
          label: 'Continue',
          next: QuestionIds.Q12
        }
      ]
    }
  },
  [ResponseIds.Q11A5]: {
    id: ResponseIds.Q11A5,
    type: StepType.RESPONSE,
    screenData: {
      title: 'We got you!',
      body: 'Many people mix up joy (fleeting and mindful in the moment) and fulfillment (sometimes painful yet enduring).  Joy can be experienced and unnoticed or lacking and in need of development.\n\nA guide sounds like a good fit to help you understand the importance of joy, appreciating it, and discovering your own source(s) to tap into!',
      options: [
        {
          type: AnswerType.BUTTON,
          label: 'Continue',
          next: QuestionIds.Q12
        }
      ]
    }
  },
  [QuestionIds.Q12]: {
    id: QuestionIds.Q12,
    type: StepType.QUESTION,
    screenData: {
      title: 'Internal Self',
      body: 'Rate your frequency of experiencing a sense of fulfillment and accomplishment',
      options: [
        {
          type: FEAT_ANSWER_TYPE,
          label: "If I make it to the shower and get breakfast I'm winning.",
          next: ResponseIds.Q12A1
        },
        {
          type: FEAT_ANSWER_TYPE,
          label: "Closest thing to fulfillment is finishing all the nachos after my sibling bets I can't.",
          next: ResponseIds.Q12A2
        },
        {
          type: FEAT_ANSWER_TYPE,
          label: "Didn't I just answer this with joy? (nope and we'll explain why in your results).",
          next: ResponseIds.Q12A3
        },
        {
          type: FEAT_ANSWER_TYPE,
          label: 'I experience fulfillment for the most part in my job and but especially in my volunteer cat sweater knitting gig.',
          next: ResponseIds.Q12A4
        },
        {
          type: FEAT_ANSWER_TYPE,
          label: 'If I will it, I get it! In all things: my job, at home, volunteering, and my side gig.. if not, well, failure is not an option',
          next: ResponseIds.Q12A5
        }
      ]
    }
  },
  [ResponseIds.Q12A1]: {
    id: ResponseIds.Q12A1,
    type: StepType.RESPONSE,
    screenData: {
      title: 'We got you!',
      body: "Many people mix up joy (fleeting and mindful in the moment) and fulfillment (sometimes painful yet enduring). We recommend reading the version summarized by comedian comic artist, The Oatmeal, on clarifying the power of fulfillment.\n\nIf you're unsure how this impacts your health, a guide sounds like a good fit to help you understand the importance and find a source of fulfillment to sustain you!",
      options: [
        {
          type: AnswerType.BUTTON,
          label: 'Continue',
          next: QuestionIds.Q13
        }
      ]
    }
  },
  [ResponseIds.Q12A2]: {
    id: ResponseIds.Q12A2,
    type: StepType.RESPONSE,
    screenData: {
      title: 'We got you!',
      body: "Many people mix up joy (fleeting and mindful in the moment) and fulfillment (sometimes painful yet enduring). We recommend reading the version summarized by comedian comic artist, The Oatmeal, on clarifying the power of fulfillment.\n\nIf you're unsure how this impacts your health, a guide sounds like a good fit to help you understand the importance and find a source of fulfillment to sustain you!",
      options: [
        {
          type: AnswerType.BUTTON,
          label: 'Continue',
          next: QuestionIds.Q13
        }
      ]
    }
  },
  [ResponseIds.Q12A3]: {
    id: ResponseIds.Q12A3,
    type: StepType.RESPONSE,
    screenData: {
      title: 'We got you!',
      body: "Many people mix up joy (fleeting and mindful in the moment) and fulfillment (sometimes painful yet enduring). We recommend reading the version summarized by comedian comic artist, The Oatmeal, on clarifying the power of fulfillment.\n\nIf you're unsure how this impacts your health, a guide sounds like a good fit to help you understand the importance and find a source of fulfillment to sustain you!",
      options: [
        {
          type: AnswerType.BUTTON,
          label: 'Continue',
          next: QuestionIds.Q13
        }
      ]
    }
  },
  [ResponseIds.Q12A4]: {
    id: ResponseIds.Q12A4,
    type: StepType.RESPONSE,
    screenData: {
      title: 'GREAT!',
      body: "We're so happy you know the difference between joy (fleeting) and fulfillment (sometimes painful yet enduring). But if you want to double check your definitions check out our recommended reading from The Oatmeal, How to Be Perfectly Unhappy.\n\nOr, if you want assess the longevity of your current source of fulfillment setup a one-on-one with a Guide to ensure you're set for an epic-long journey worth of fulfillment.",
      options: [
        {
          type: AnswerType.BUTTON,
          label: 'Continue',
          next: QuestionIds.Q13
        }
      ]
    }
  },
  [ResponseIds.Q12A5]: {
    id: ResponseIds.Q12A5,
    type: StepType.RESPONSE,
    screenData: {
      title: 'We got you!',
      body: "Many people mix up joy (fleeting and mindful in the moment) and fulfillment (sometimes painful yet enduring). We recommend reading the version summarized by comedian comic artist, The Oatmeal, on clarifying the power of fulfillment.\n\nIf you're unsure how this impacts your health, a guide sounds like a good fit to help you understand the importance and find a source of fulfillment to sustain you!",
      options: [
        {
          type: AnswerType.BUTTON,
          label: 'Continue',
          next: QuestionIds.Q13
        }
      ]
    }
  },
  [QuestionIds.Q13]: {
    id: QuestionIds.Q13,
    type: StepType.QUESTION,
    screenData: {
      title: 'Internal Self',
      body: 'Rate your sense of purpose and belonging',
      options: [
        {
          type: FEAT_ANSWER_TYPE,
          label: "I belong to my bedroom, but that's about it.",
          next: ResponseIds.Q13A1
        },
        {
          type: FEAT_ANSWER_TYPE,
          label: "Could use some more belonging. When I'm expected to answer the phone I feel purpose and annoyance.",
          next: ResponseIds.Q13A2
        },
        {
          type: FEAT_ANSWER_TYPE,
          label: 'I feel both often but fear I might lose it over time. I have a job and/or family, is that purpose enough?',
          next: ResponseIds.Q13A3
        },
        {
          type: FEAT_ANSWER_TYPE,
          label: 'I feel purpose in many ways that will last with life changes. Feeling belonging in my social groups and environments.',
          next: ResponseIds.Q13A4
        },
        {
          type: FEAT_ANSWER_TYPE,
          label: "I feel either or both belonging and purpose, but don't mind when I don't.",
          next: ResponseIds.Q13A5
        }
      ]
    }
  },
  [ResponseIds.Q13A1]: {
    id: ResponseIds.Q13A1,
    type: StepType.RESPONSE,
    screenData: {
      title: "We're getting deep on this one aren't we? Yes, we sure are.",
      body: "But it's for a good reason.\n\nBecause it's an important final piece of the joy-fulfillment-belonging-purpose pyramid. If you have all four you're lined up to potentially have fewer years of morbidity, more years of living, and a greater sense of overall satisfaction (Blue Zone studies are pretty neat if you haven't heard of them).\n\n\nA guide sounds like a good fit to help you explore the uncharted maps of this unique area of health.",
      options: [
        {
          type: AnswerType.BUTTON,
          label: 'Continue',
          next: QuestionIds.FIN
        }
      ]
    }
  },
  [ResponseIds.Q13A2]: {
    id: ResponseIds.Q13A2,
    type: StepType.RESPONSE,
    screenData: {
      title: "We're getting deep on this one aren't we? Yes, we sure are.",
      body: "But it's for a good reason.\n\nBecause it's an important final piece of the joy-fulfillment-belonging-purpose pyramid. If you have all four you're lined up to potentially have fewer years of morbidity, more years of living, and a greater sense of overall satisfaction (Blue Zone studies are pretty neat if you haven't heard of them).\n\n\nA guide sounds like a good fit to help you explore the uncharted maps of this unique area of health.",
      options: [
        {
          type: AnswerType.BUTTON,
          label: 'Continue',
          next: QuestionIds.FIN
        }
      ]
    }
  },
  [ResponseIds.Q13A3]: {
    id: ResponseIds.Q13A3,
    type: StepType.RESPONSE,
    screenData: {
      title: "We're getting deep on this one aren't we? Yes, we sure are.",
      body: "But it's for a good reason.\n\nBecause it's an important final piece of the joy-fulfillment-belonging-purpose pyramid. If you have all four you're lined up to potentially have fewer years of morbidity, more years of living, and a greater sense of overall satisfaction (Blue Zone studies are pretty neat if you haven't heard of them).\n\n\nA guide sounds like a good fit to help you explore the uncharted maps of this unique area of health.",
      options: [
        {
          type: AnswerType.BUTTON,
          label: 'Continue',
          next: QuestionIds.FIN
        }
      ]
    }
  },
  [ResponseIds.Q13A4]: {
    id: ResponseIds.Q13A4,
    type: StepType.RESPONSE,
    screenData: {
      title: 'WAHOO!',
      body: "You're doing pretty alright, I must say. This is the final piece of the joy-fulfillment-belonging-purpose pyramid. If you have all four you're lined up to potentially have fewer years of morbidity, more years of living, and a greater sense of overall satisfaction (Blue Zone studies are pretty neat if you haven't heard of them).\n\nLet us know if you have anything the world should know about your success!\n\nIf you find yourself seeking new purpose or in need of belonging somewhere else book a Reservation Only session with Health Atlas Guide to explore what else is out there!",
      options: [
        {
          type: AnswerType.BUTTON,
          label: 'Continue',
          next: QuestionIds.FIN
        }
      ]
    }
  },
  [ResponseIds.Q13A5]: {
    id: ResponseIds.Q13A5,
    type: StepType.RESPONSE,
    screenData: {
      title: "We're getting deep on this one aren't we? Yes, we sure are.",
      body: "But it's for a good reason.\n\nBecause it's an important final piece of the joy-fulfillment-belonging-purpose pyramid. If you have all four you're lined up to potentially have fewer years of morbidity, more years of living, and a greater sense of overall satisfaction (Blue Zone studies are pretty neat if you haven't heard of them).\n\n\nA guide sounds like a good fit to help you explore the uncharted maps of this unique area of health.",
      options: [
        {
          type: AnswerType.BUTTON,
          label: 'Continue',
          next: QuestionIds.FIN
        }
      ]
    }
  },
  // Temp
  [QuestionIds.FIN]: {
    id: QuestionIds.FIN,
    type: StepType.RESPONSE,
    screenData: {
      title: '%s',
      body: '%s'
    }
  },
  /**
   * Managed by @see RevealStep.vue
   */
  [QuestionIds.DEMOGRAPHIC]: {
    id: QuestionIds.DEMOGRAPHIC,
    type: StepType.RESPONSE,
    screenData: {
      title: '%s',
      body: '%s'
    }
  }
}

export default STEPS
