export enum MajorPointOfInterest {
  CORE = 'CORE',
  EPICORE = 'EPICORE',
  INTERNAL_SELF = 'INTERNAL_SELF',
  RESOURCES = 'RESOURCES',
  SOCIAL = 'SOCIAL',
  EXTERNAL_SELF = 'EXTERNAL_SELF'
}

export enum MinorPointOfInterest {
  // CORE
  MENTAL = 'MENTAL',
  NUTRIENTS = 'NUTRIENTS',
  PHYSICAL = 'PHYSICAL',
  // EPICORE
  AIR = 'AIR',
  SLEEP = 'SLEEP',
  WATER = 'WATER',
  GREENERY = 'GREENERY',
  ORDER = 'ORDER',
  // INTERNAL_SELF
  JOY = 'JOY',
  ACCOMPLISHMENT = 'ACCOMPLISHMENT',
  PURPOSE = 'PURPOSE',
  BELONGING = 'BELONGING',
  FULFILLMENT = 'FULFILLMENT',
  // RESOURCES
  ECONOMICS = 'ECONOMICS',
  EDUCATION = 'EDUCATION',
  CAREER = 'CAREER',
  // SOCIAL
  SELF = 'SELF',
  OTHER = 'OTHER',
  HOME = 'HOME',
  // EXTERNAL_SELF
  ME_TIME = 'ME_TIME',
  CONFIDENCE = 'CONFIDENCE',
  CREATIVITY_ACTIVE = 'CREATIVITY_ACTIVE',
  CREATIVITY_PASSIVE = 'CREATIVITY_PASSIVE',
  CURIOSITY = 'CURIOSITY'
}

export type PointOfInterest = MajorPointOfInterest | MinorPointOfInterest
