
import { defineComponent } from 'vue'
import store from '@/store'

import * as ActionTypes from '@/store/actionTypes'
import Answer, { AnswerType } from '@/models/Answer'
import { QuestionIds, RevealQuestionIds } from '@/data/steps'

export default defineComponent({
  name: 'RevealStep',
  computed: {
    shouldShowNextStep () {
      return (
        this.age !== '' && this.email !== '' && !this.emailError && this.gender !== ''
      )
    }
  },
  data: () => ({
    age: '',
    email: '',
    emailError: false,
    gender: '',
    perceivedAgeOptions: [
      {
        type: AnswerType.BUTTON,
        label: 'Young and feel young and spritely (#ohyeah)',
        // `next` is not actually used, so it is set to a dummy value for typing checks
        next: QuestionIds.Q1
      },
      {
        type: AnswerType.BUTTON,
        label: 'Young and feel worn out (euf)',
        // `next` is not actually used, so it is set to a dummy value for typing checks
        next: QuestionIds.Q1
      },
      {
        type: AnswerType.BUTTON,
        label: "Somewhere in between young and old, spry and worn (good days and bad day hey I'm doing okay!)",
        // `next` is not actually used, so it is set to a dummy value for typing checks
        next: QuestionIds.Q1
      },
      {
        type: AnswerType.BUTTON,
        label: 'Old and feel young (#winning)',
        // `next` is not actually used, so it is set to a dummy value for typing checks
        next: QuestionIds.Q1
      },
      {
        type: AnswerType.BUTTON,
        label: 'Old and feel every single minute of life (also euf)',
        // `next` is not actually used, so it is set to a dummy value for typing checks
        next: QuestionIds.Q1
      },
      {
        type: AnswerType.BUTTON,
        label: 'Prefer not to give anything up until you give me something, maybe.',
        // `next` is not actually used, so it is set to a dummy value for typing checks
        next: QuestionIds.Q1
      }
    ]
  }),
  methods: {
    nextStep () {
      // Hard-coded for now
      const nextStep = QuestionIds.Q1
      store.dispatch(ActionTypes.NEXT_STEP, {
        step: nextStep
      })
    },
    /**
     * @param answer Should be type @see Answer
     */
    addResponseValue (questionId: RevealQuestionIds, answer: string) {
      store.dispatch(ActionTypes.ADD_RESPONSE_VALUE, {
        questionId,
        answer
      })
    },
    onAgeSelect (event: Event) {
      this.addResponseValue(RevealQuestionIds.Q2, (event?.target as HTMLInputElement).value)
    },
    onPerceivedAgeSelect (answer: Answer) {
      this.addResponseValue(RevealQuestionIds.Q3, answer.label)
    }
  },
  watch: {
    email (newEmail: string) {
      this.emailError = !(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(newEmail))
      if (!this.emailError) {
        this.addResponseValue(RevealQuestionIds.Q1, newEmail)
      }
    },
    gender (selection: string) {
      this.addResponseValue(RevealQuestionIds.Q4, selection)
    }
  }
})
