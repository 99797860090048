import ScreenData from '@/models/ScreenData'

export enum StepType {
  QUESTION = 'question',
  RESPONSE = 'response'
}

export default interface Step {
  id: string,
  type: StepType,
  screenData: ScreenData,
}
